import { AppStats, AppStatsGrid } from "./app-stats";
import { Dashboard } from "./dashboard";
import { IpInfo } from "./ip-info";
import { Speed, SpeedGrid } from "./speed/speed";

export const App = () => (
    <Dashboard>
        <IpInfo />
        <AppStatsGrid>
            <AppStats name="Office" url="http://portal.office.com/" />
            <AppStats name="Teams" url="http://teams.microsoft.com/" />
            <AppStats name="Salesforce" url="https://login.salesforce.com/" />
            <AppStats name="Zoom" url="http://zoom.us/" />
        </AppStatsGrid>
        <SpeedGrid>
            <Speed
                name="AWS US East"
                url="https://bqvowjqov4iso434tsv7nwnn7y0aunfu.lambda-url.us-east-1.on.aws/"
                providerUrl="https://aws.amazon.com"
            />
        </SpeedGrid>
    </Dashboard>
);
