import { CloudIcon, GlobeAltIcon, MapPinIcon, ServerIcon } from "@heroicons/react/24/outline";
import useSWR from "swr";

import { Card } from "../components/card";
import { fetchIpInfo } from "./api";

import sampleImgUrl from "url:./sample.jpg";

const useIpInfo = () => {
    const { data, error } = useSWR("ipinfo", fetchIpInfo, { revalidateOnFocus: false });
    return {
        ipInfo: data,
        isLoading: !error && !data,
        isError: error,
    };
};

export const IpInfo = () => {
    const { ipInfo, isLoading, isError } = useIpInfo();

    if (isLoading) return <p>Loading IP info...</p>;

    if (isError) return <p>Error retrieving IP info</p>;

    const mapUrl =
        process.env.NODE_ENV === "development"
            ? sampleImgUrl
            : `https://maps.geoapify.com/v1/staticmap?style=klokantech-basic&width=600&height=240&center=lonlat:${ipInfo.longitude},${ipInfo.latitude}&zoom=10&apiKey=${process.env.GEOAPIFY_KEY}`;

    return (
        <Card className="text-sm flex flex-col gap-y-2 min-w-max">
            <div
                style={{ height: 240, backgroundImage: `url(${mapUrl})` }}
                className="w-full bg-no-repeat bg-center bg-origin-border bg-clip-border bg-cover"
            ></div>
            <div className="flex items-center gap-x-1">
                <GlobeAltIcon width={16} height={16} className="shrink-0" />
                IP Version: <span className="font-medium ml-1">{ipInfo.version}</span>
            </div>
            <div className="flex items-center gap-x-1">
                <MapPinIcon width={16} height={16} className="shrink-0" />
                Location:
                <span className="font-medium ml-1">
                    {ipInfo.city}, {ipInfo.region}, {ipInfo.country_code_iso3}
                </span>
            </div>
            <div className="flex items-center gap-x-1">
                <CloudIcon width={16} height={16} className="shrink-0" />
                ISP:
                <span className="font-medium ml-1">
                    {ipInfo.org} ({ipInfo.asn})
                </span>
            </div>
            <div className="flex items-center gap-x-1">
                <ServerIcon width={16} height={16} className="shrink-0" />
                IP Address:
                <span className="font-medium ml-1">{ipInfo.ip}</span>
            </div>
        </Card>
    );
};
