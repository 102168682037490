import { useEffect, useState } from "react";

import { AppLogo } from "../components/app-logo";
import { Card } from "../components/card";
import { createWorker } from "../worker/api";
import { speedTestRequest, SpeedTestRequest, SpeedTestResponse } from "./worker";

export const Speed = ({ name, url, providerUrl }: { name: string; url: string; providerUrl: string }) => (
    <div className="py-2 border border-gray-300 rounded-md flex flex-col gap-4 items-center font-bold bg-white">
        <div className="flex flex-col items-center">
            <div className="h-[80px] flex flex-col items-center justify-center">
                <AppLogo url={providerUrl} />
            </div>
            <div className="font-normal">{name}</div>
        </div>
        <Stats url={url} />
    </div>
);

export const SpeedGrid = ({ children }: React.PropsWithChildren) => (
    <Card className="flex flex-col justify-between bg-gray-100">
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">{children}</div>
        <div className="mt-4 text-gray-500 text-center text-sm">
            Speeds in Mbps. <a href="https://clearbit.com">Logos provided by Clearbit.</a>
        </div>
    </Card>
);

const Stats = ({ url }: { url: string }) => {
    const [up, setUp] = useState<number | undefined>();
    const [down, setDown] = useState<number | undefined>();

    useEffect(() => {
        const workerUrl = new URL("worker.ts", import.meta.url);
        const { worker, postMessage } = createWorker<SpeedTestRequest, SpeedTestResponse>(workerUrl, ({ up, down }) => {
            if (up) setUp(up);
            if (down) setDown(down);
        });
        postMessage(speedTestRequest(url));

        return () => {
            worker.terminate();
        };
    }, []);

    return (
        <>
            <div className="flex flex-col items-center">
                Down
                <span className="text-3xl">{down ? bpsToString(down) : "?"}</span>
            </div>
            <div className="flex flex-col items-center">
                Up
                <span className="text-3xl">{up ? bpsToString(up) : "?"}</span>
            </div>
        </>
    );
};

// Use this to customize the display unit, e.g., Mbps, Mibps, etc.
const bpsToString = (bps: number) => (bps / 1000000).toFixed(1);
