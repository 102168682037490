import { PropsWithChildren, useEffect, useState } from "react";

import { AppLogo } from "../components/app-logo";
import { Card } from "../components/card";
import { createWorker } from "../worker/api";
import { PingResponse, pingMessage, PingRequest } from "./api";

export const AppStats = ({ name, url }: { name: string; url: string }) => (
    <div className="py-2 border border-gray-300 rounded-md flex flex-col gap-4 items-center font-bold bg-white">
        <div className="flex flex-col items-center">
            <div className="h-[80px] flex flex-col items-center justify-center">
                <AppLogo url={url} />
            </div>
            <div className="font-normal">{name}</div>
        </div>
        <Stats url={url} />
    </div>
);

export const AppStatsGrid = ({ children }: PropsWithChildren) => (
    <Card className="flex flex-col justify-between bg-gray-100">
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">{children}</div>
        <div className="mt-4 text-gray-500 text-center text-sm">
            Times in milliseconds. <a href="https://clearbit.com">Logos provided by Clearbit.</a>
        </div>
    </Card>
);

const Stats = ({ url }: { url: string }) => {
    const [rtt, setRtt] = useState(0);
    const [jitter, setJitter] = useState(0);

    useEffect(() => {
        const workerUrl = new URL("worker.ts", import.meta.url);
        const { worker, postMessage } = createWorker<PingRequest, PingResponse>(workerUrl, ({ payload }) => {
            setRtt(payload.rtt);
            setJitter(payload.jitter);
        });
        postMessage(pingMessage(url));

        return () => {
            worker.terminate();
        };
    }, []);

    return (
        <>
            <div className="flex flex-col items-center">
                Round trip
                <span className="text-3xl">{rtt.toFixed(1)}</span>
            </div>
            <div className="flex flex-col items-center">
                Jitter
                <span className="text-3xl">{jitter.toFixed(1)}</span>
            </div>
        </>
    );
};
