{
    "ip": "2607:ff48:aa81:2602::b25d",
    "network": "2607:ff48:aa81::/48",
    "version": "IPv6",
    "city": "Miami",
    "region": "Florida",
    "region_code": "FL",
    "country": "US",
    "country_name": "United States",
    "country_code": "US",
    "country_code_iso3": "USA",
    "country_capital": "Washington",
    "country_tld": ".us",
    "continent_code": "NA",
    "in_eu": false,
    "postal": "33132",
    "latitude": 25.7806,
    "longitude": -80.1826,
    "timezone": "America/New_York",
    "utc_offset": "-0400",
    "country_calling_code": "+1",
    "currency": "USD",
    "currency_name": "Dollar",
    "languages": "en-US,es-US,haw,fr",
    "country_area": 9629091.0,
    "country_population": 327167434,
    "asn": "AS8100",
    "org": "ASN-QUADRANET-GLOBAL"
}
