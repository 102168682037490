const ONE_KILOBYTE = 1024;
const ONE_HUNDRED_KILOBYTES = 100 * ONE_KILOBYTE;
const ONE_MEGABYTE = 1024 * ONE_KILOBYTE;
const TWO_MEGABYTES = 2 * ONE_MEGABYTE;

// Tuples of (payload size, number of samples)
const TESTS = [
    [ONE_HUNDRED_KILOBYTES, 20], // i.e., do 20 sample of 100KB payloads
    [ONE_MEGABYTE, 10],
    [TWO_MEGABYTES, 5],
];

export type SpeedTestRequest = {
    url: string;
};

export type SpeedTestResponse = {
    up?: number;
    down?: number;
};

export const speedTestRequest = (url: string): SpeedTestRequest => ({
    url,
});

const speedTestResponse = ({ up, down }: SpeedTestResponse): SpeedTestResponse => ({
    up,
    down,
});

// in bits per second
const measureDownload = async (url: string, bytes: number) => {
    const t0 = performance.now();
    await fetch(`${url}?size=${bytes}`);
    const s = (performance.now() - t0) / 1000;
    const bps = (bytes * 8) / s;
    return bps;
};

// in bps
const measureUpload = async (url: string, bytes: number) => {
    const body = new Uint8Array(bytes).fill(97);
    const t0 = performance.now();
    await fetch(url, { body, method: "POST" });
    const s = (performance.now() - t0) / 1000;
    const bps = (bytes * 8) / s;
    return bps;
};

// Given an array of bps measurments, return something meaningful, e.g., mean or median.
const averageBps = (measurements: number[]) => measurements.reduce((sum, i) => sum + i) / measurements.length;

addEventListener("message", async (e: MessageEvent<SpeedTestRequest>) => {
    const { url } = e.data;

    // warm up
    await Promise.all(Array.from({ length: 5 }).map(() => fetch(e.data.url, { method: "head" })));

    const downs = [];
    for (const [payload, samples] of TESTS) {
        for (let i = 0; i < samples; i++) {
            downs.push(await measureDownload(url, payload));
            setTimeout(() => {
                postMessage(speedTestResponse({ down: averageBps(downs) }));
            }, 100);
        }
    }

    const ups = [];
    for (const [payload, samples] of TESTS) {
        for (let i = 0; i < samples; i++) {
            ups.push(await measureUpload(url, payload));
            setTimeout(() => {
                postMessage(speedTestResponse({ up: averageBps(ups) }));
            }, 100);
        }
    }
});
