import { PhotoIcon } from "@heroicons/react/24/outline";

export const AppLogo = ({ url }: { url: string }) => {
    try {
        const u = new URL(url);
        const src = `https://logo.clearbit.com/salesforce.com/${u.hostname}?size=80`;
        return <img height={80} width={80} src={src} />;
    } catch {
        return <PhotoIcon height={80} width={80} />;
    }
};
