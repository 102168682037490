type WorkerUrl = ConstructorParameters<typeof Worker>[0];

type ResponseHandler<M> = (message: M) => void;

export const createWorker = <Req, Resp>(url: WorkerUrl, handler: ResponseHandler<Resp>) => {
    const worker = new Worker(url, { type: "module" });
    const postMessage = (message: Req) => worker.postMessage(message);
    worker.addEventListener("message", (e: MessageEvent<Resp>) => {
        handler(e.data);
    });
    return { worker, postMessage };
};
