export type PingRequest = {
    action: "ping";
    payload: {
        url: string;
    };
};

export const pingMessage = (url: string): PingRequest => ({
    action: "ping",
    payload: { url },
});

export type PingResponse = {
    type: "ping";
    payload: {
        url: string;
        rtt: number;
        jitter: number;
    };
};

export const pingResponse = (url: string, rtt: number, jitter: number): PingResponse => ({
    type: "ping",
    payload: { url, rtt, jitter },
});
