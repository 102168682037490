import sample from "./sample.json";

export type IpInfo = {
    asn: string;
    city: string;
    continent_code: string;
    country: string;
    country_area: number;
    country_calling_code: string;
    country_capital: string;
    country_code: string;
    country_code_iso3: string;
    country_name: string;
    country_tld: string;
    currency: string;
    currency_name: string;
    hostname: string;
    ip: string;
    languages: string;
    org: string;
    postal: string;
    region: string;
    region_code: string;
    timezone: string;
    type: string;
    utc_offset: string;
    version: string;
    in_eu: boolean;
    country_population: number;
    latitude: number;
    longitude: number;
};

export const fetchIpInfo = async () => {
    if (process.env.NODE_ENV === "development") {
        console.log("using sample IP info");
        return sample;
    }
    try {
        const resp = await fetch("https://ipapi.co/json/");
        if (resp.ok) {
            const data = (await resp.json()) as IpInfo;
            return data;
        } else {
            return null;
        }
    } catch (e) {
        console.warn("failed to get client info", e);
        return null;
    }
};
