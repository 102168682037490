import { BeakerIcon } from "@heroicons/react/24/outline";

type Props = {
    children: React.ReactNode;
};

export const Dashboard = ({ children }: Props) => (
    <div className="container p-2 sm:mx-auto sm:p-0 sm:py-5">
        <header className="mb-4">
            <h1 className="text-2xl font-bold whitespace-nowrap text-gray-900 flex gap-x-2 items-center flex-nowrap">
                <BeakerIcon height={24} width={24} className="shrink-0" /> Performance Dashboard
            </h1>
        </header>
        <main className="grid lg:grid-cols-2 gap-4">{children}</main>
    </div>
);
